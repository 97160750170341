//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/pages/portfolios
// Description: Portfolios overview page.
// Created:     FUB, May 15, 2022
//==============================================================================

import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Scatter } from 'react-chartjs-2';

import { usePortfolioList } from "../utils/portfolio-list"
import { usePortfolioOverview } from "../utils/portfolio-data"
import { useMembership } from "../utils/member-data";

import { Page } from "../components/layout/page"
import { ButtonHelp, ButtonPrimary, ButtonSecondary } from "../components/widgets/button"
import { Link } from "../components/widgets/link"
import { TableSimple, TableSortable } from "../components/widgets/table"
import { PortfolioWiz } from "../components/widgets/portfolio-wiz"
import { BoxBorder } from "../components/widgets/container"
import { formatCurrency } from "../utils/format-helpers"
import { theme } from "../components/layout/theme"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`PORTFOLIOS: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`PORTFOLIOS: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
const Badge = ({ premium }) => (
    <span
        premium={premium}
        css={`
        font-size: 0.70em;
        letter-spacing: -0.05em;
        vertical-align: super;
        font-style: italic;
        color: ${props => props.premium === true ? props.theme.colors.green.base : props.theme.colors.blue.base};
    `}
    >
        {premium === true ? " Premium" : " Basic"}
    </span>)

//------------------------------------------------------------------------------
const PortfolioScatter = ({ data, filtered }) => {
    //--- plugin to add data labels to scatter chart
    const scatterDataLabels = {
        // see https://www.youtube.com/watch?v=PNbDrDI97Ng
        id: `scatterDataLabels`,
        afterDatasetsDraw(chart, args, options) {
            const { ctx } = chart
            ctx.save()
            ctx.font = '0.7em sans-serif'

            for (let x = 0; x < chart.config.data.datasets.length; x++) {
                const dataset = chart.config.data.datasets[x]
                const meta = chart.getDatasetMeta(x)

                if (dataset.data.length > 10) continue

                for (let i = 0; i < dataset.data.length; i++) {
                    const datapoint = dataset.data[i]
                    const label = datapoint.name
                    const labelWidth = ctx.measureText(label).width
                    const x = meta.data[i].x
                    const y = meta.data[i].y + 5

                    ctx.fillText(label, x - labelWidth / 2, y + 12)
                }
            }

            ctx.restore()
        },
    }

    //--- chart options
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.78,
        interaction: {
            mode: 'nearest',
            intersect: false,
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: filtered === true ? "Reward: Estimated Return [%]" : "Reward: Average Return [%]",
                    color: theme.colors.accent,
                },
                grid: {
                    borderColor: theme.colors.decorative,
                },
                grace: '5%',
            },
            x: {
                title: {
                    display: true,
                    text: "Risk: Ulcer Index [%]",
                    color: theme.colors.accent,
                },
                grid: {
                    borderColor: theme.colors.decorative,
                },
                grace: '5%',
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Portfolio Comparison: Reward vs Risk'
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return context[0].raw.name
                    },
                    label: function (context) {
                        const data = context.raw
                        return [
                            `Average Return = ${data.cagr}%`,
                            `Maximum Drawdown = ${data.mdd}%`,
                            `Martin Ratio = ${data.martin}`,
                        ]
                    },
                    // see https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
                    /*labelPointStyle: function (context) {
                        return {
                            pointStyle: 'triangle',
                            rotation: 0,
                        }
                    },*/
                },
            },
        },
        onClick: function (event, activeElements) {
            if (activeElements.length > 0) {
                // clicked on at least one element
                const element = activeElements[0]
                const dataset = element.datasetIndex
                const index = element.index
                const data = event.chart.config.data.datasets[dataset].data[index]
                navigate(data.url)
            }
        },
        animation: false, // see https://www.chartjs.org/docs/latest/configuration/animations.html
        animations: {
            colors: false,
            x: false,
        },
        transitions: {
            active: {
                animation: {
                    duration: 0,
                }
            }
        },
    }

    //--- compile chart data
    const [defaultChartData, /*setDefaultChartData*/] = useState({
        datasets: [{
            label: "portfolios",
            data: [{
                name: "Portfolio A",
                y: 15,
                x: 5,
                mdd: 25,
                martin: 1,
            }, {
                name: "Portfolio B",
                y: 14,
                x: 4.6,
                mdd: 20,
                martin: 1.2,
            }, {
                name: "Portfolio C",
                y: 12.5,
                x: 4.2,
                mdd: 15,
                martin: 1.5,
            }, {
                name: "Portfolio D",
                y: 10,
                x: 4,
                mdd: 10,
                martin: 2.0,
            }],
            backgroundColor: theme.colors.blue.base,
        }]
    })

    const [theChartData, setChartData] = useState(defaultChartData)
    useEffect(() => {
        if (!data) return

        const chartData = {
            ...defaultChartData,
            datasets: [{
                ...defaultChartData.datasets[0],
                data: data?.["Name-TextOnly"]?.map((name, index) => {
                    return {
                        name: name,
                        x: parseFloat(data["Ulcer Index"][index]),
                        y: filtered === true ?
                            parseFloat(data["Estimated Return"][index]) :
                            parseFloat(data["Average Return"][index]),
                        cagr: parseFloat(data["Average Return"][index]),
                        mdd: parseFloat(data["Max Drawdown"][index]),
                        martin: parseFloat(data["Martin Ratio"][index]),
                        url: data["URL"][index],
                    }
                }),
                //backgroundColor: data?.["Name-TextOnly"]?.map((name, index) => name.startsWith("TT's") ? theme.colors.green.base : theme.colors.blue.base),
                backgroundColor: data?.["Name-TextOnly"]?.map((name, index) => name.startsWith("TT's") ? `${theme.colors.green.base}b0` : `${theme.colors.blue.base}b0`),
                pointRadius: data?.["Name-TextOnly"]?.map((name, index) => name.includes("All-Stars") ? 10 : 7),
            }]
        }
        setChartData(chartData)
    }, [data, defaultChartData, filtered])

    //--- render chart
    return (<>
        <div css={`position:relative;`}>
            <div css={`display:inline-block;width:0px;padding-bottom:56%;`} />
            <div css={`position:absolute;top:0;left:0;width:100%;height:100%;`}>
                <div css={`position:relative;`}>
                    <Scatter options={chartOptions} data={theChartData} plugins={[scatterDataLabels]} />
                </div>
            </div>
        </div>
    </>)
}

//------------------------------------------------------------------------------
const PortfolioTable = ({ data, filtered, sortable }) => {
    const sortSpec = {
        defaultColumn: filtered ? "Estimated Return" : "Martin Ratio",
        "Name": { alpha: true, ascending: true, sortBy: "Name-TextOnly" },

        "Average Return": { alpha: false, ascending: false },
        "Estimated Return": { alpha: false, asending: false },

        "Max Drawdown": { alpha: false, ascending: true },
        "Estimated Drawdown": { alpha: false, ascending: true },

        "Martin Ratio": { alpha: false, ascending: false },
        "Estimated Martin Ratio": { alpha: false, ascending: false },
    }

    return sortable === true ? (
        <TableSortable data={data} sort={sortSpec} />
    ) : (
        <TableSimple data={data} />
    )
}

//------------------------------------------------------------------------------
const PortfoliosPage = ({ location }) => {
    const [showWiz, setWiz] = useState(false)
    const membership = useMembership()
    const memberId = membership?.user?.id

    //---------- open wizard based on URL parameters
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)

        if (searchParams.get('wizard'))
            setWiz(true)
    }, [location.search])

    //---------- merge filter parameters with default values
    const [portfolioFilter, setPortfolioFilter] = useState()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const portfolioFilterRaw = {
            dollarsToInvest: searchParams.get('c'),
            yearsToInvest: searchParams.get('y'),
            taxableAccount: searchParams.get('t'),
            maxDrawdown: searchParams.get('r'),
            tag: searchParams.get('tag'),
        }
        DEBUG_MSG(`dollarsToInvest (${typeof portfolioFilterRaw.dollarsToInvest}) = ${portfolioFilterRaw.dollarsToInvest}`)
        DEBUG_MSG(`yearsToInvest (${typeof portfolioFilterRaw.yearsToInvest}) = ${portfolioFilterRaw.yearsToInvest}`)
        DEBUG_MSG(`taxableAccount (${typeof portfolioFilterRaw.taxableAccount}) = ${portfolioFilterRaw.taxableAccount}`)
        DEBUG_MSG(`maxDrawdown (${typeof portfolioFilterRaw.maxDrawdown}) = ${portfolioFilterRaw.maxDrawdown}`)
        DEBUG_MSG(`tag (${typeof portfolioFilterRaw.tag}) = ${portfolioFilterRaw.tag}`)

        const hasFilter = portfolioFilterRaw.dollarsToInvest ||
            portfolioFilterRaw.yearsToInvest ||
            portfolioFilterRaw.taxableAccount ||
            portfolioFilterRaw.maxDrawdown ||
            portfolioFilterRaw.tag

        const theFilter = hasFilter ? {
            dollarsToInvest: portfolioFilterRaw.dollarsToInvest && parseFloat(portfolioFilterRaw.dollarsToInvest),
            yearsToInvest: portfolioFilterRaw.yearsToInvest && parseFloat(portfolioFilterRaw.yearsToInvest),
            taxableAccount: portfolioFilterRaw.taxableAccount && parseInt(portfolioFilterRaw.taxableAccount),
            maxDrawdown: portfolioFilterRaw.maxDrawdown && parseFloat(portfolioFilterRaw.maxDrawdown),
            tag: portfolioFilterRaw.tag,
        } : null

        DEBUG_MSG(`theFilter = ${JSON.stringify(theFilter)}`)
        setPortfolioFilter(theFilter)
    }, [location.search])

    //---------- enrich portfolio list with metrics
    const portfolioList = usePortfolioList()
    const portfolioMetrics = usePortfolioOverview()
    const [portfolioListMetrics, setPortfolioListMetrics] = useState()

    useEffect(() => {
        if (!portfolioList || !portfolioMetrics) return

        const yearsIndex = Math.min(24, Math.max(0,
            Math.floor(portfolioFilter?.yearsToInvest ?? 24) - 1))
        //const yearsIndex = 24

        const isTaxable = portfolioFilter?.taxableAccount && portfolioFilter.taxableAccount !== 0
        //const isTaxable = true
        const shortTermTaxRate = 0.37 // anywhere from 10% to 37%
        const longTermTaxRate = 0.15 // tax rates are 0%, 15%, 20%

        var tmp = portfolioList
            .map(info => ({
                ...info,
                ["cagr-max"]: portfolioMetrics[info.slug2]?.["cagr-max"] ?? 0, // eslint-disable-line no-useless-computed-key
                ["cagr-5th"]: portfolioMetrics[info.slug2]?.["cagr-5th"]?.[yearsIndex] ?? 0, // eslint-disable-line no-useless-computed-key

                ["mdd"]: portfolioMetrics[info.slug2]?.["mdd"] ?? 0, // eslint-disable-line no-useless-computed-key
                ["mdd-5th"]: portfolioMetrics[info.slug2]?.["mdd-5th"] ?? 0, // eslint-disable-line no-useless-computed-key

                ["martin"]: portfolioMetrics[info.slug2]?.["martin"] ?? 0, // eslint-disable-line no-useless-computed-key
                ["ulcer"]: portfolioMetrics[info.slug2]?.["ulcer"] ?? 0, // eslint-disable-line no-useless-computed-key
            }))
            .map(info => ({
                ...info,
                ["cagr-5th-taxed"]: info["cagr-5th"] * (1 - info.shortTermTaxation / 100.0) * (isTaxable ? 1 - longTermTaxRate : 1) // eslint-disable-line no-useless-computed-key
                    + info["cagr-5th"] * info.shortTermTaxation / 100.0 * (isTaxable ? 1 - shortTermTaxRate : 1),
            }))
            .map(info => ({
                ...info,
                ["martin-5th"]: info["cagr-5th-taxed"] / info["ulcer"], // eslint-disable-line no-useless-computed-key
            }))
        setPortfolioListMetrics(tmp)

        //DEBUG_MSG(JSON.stringify(tmp.filter(p => p.slug === "tt-all-stars-xl")[0]))

    }, [portfolioList, portfolioMetrics, portfolioFilter])

    //---------- apply filtering to select suitable portfolios
    const [filteredPortfolios, setFilteredPortfolios] = useState()
    const [numPortfolios, setNumPortfolios] = useState()

    useEffect(() => {
        if (!portfolioListMetrics) return

        const showPrivate = true
        const availablePortfolios = portfolioListMetrics
            .filter(info => !info.tags.includes("hidden"))
            .filter(info => !info.tags.includes("nowiz"))
            .filter(info => !info.tags.includes("private") || (showPrivate && info.tags.includes(memberId?.toString())))

        //DEBUG_MSG(`# available = ${availablePortfolios?.length}`)
        //DEBUG_MSG(`availablePortfolios = ${availablePortfolios.map(p => p.title)}`)

        if (!portfolioFilter) {
            setFilteredPortfolios(availablePortfolios)
            setNumPortfolios(null)
            return
        }

        const filteredPortfolios = availablePortfolios
            .filter(info => !portfolioFilter.tag || info.tags.includes(portfolioFilter.tag))
            .filter(info => !portfolioFilter.dollarsToInvest || info.minCapital <= portfolioFilter.dollarsToInvest)
            .filter(info => !portfolioFilter.maxDrawdown || info["mdd-5th"] < portfolioFilter.maxDrawdown)
            .filter(info => info["cagr-5th-taxed"] > 0)
            .sort((a, b) => b["cagr-5th-taxed"] - a["cagr-5th-taxed"])

        setFilteredPortfolios(filteredPortfolios)
        setNumPortfolios(filteredPortfolios.length >= 8 ? 5 : null)
    }, [portfolioListMetrics, portfolioFilter, memberId])

    //---------- apply max count to show only top portfolios
    const [visiblePortfolios, setVisiblePortfolios] = useState()

    useEffect(() => {
        if (!filteredPortfolios) return

        DEBUG_MSG(`numPortfolios = ${numPortfolios}`)
        setVisiblePortfolios(filteredPortfolios.slice(0, numPortfolios ?? 999))
    }, [filteredPortfolios, numPortfolios, memberId])

    //---------- compile data table
    const [tableData, setTableData] = useState()

    useEffect(() => {
        if (!visiblePortfolios || !portfolioMetrics) return

        const tmpTable = {
            columns: portfolioFilter ?
                ["Name", "Estimated Return", "Estimated Drawdown", "Estimated Martin Ratio"] :
                ["Name", "Average Return", "Max Drawdown", "Martin Ratio"],
            "Name": visiblePortfolios.map(p => (<>
                <Link href={`/portfolios/${p.slug}/`}>
                    {p.author.replace("TuringTrader", "TT")} {p.title}
                </Link>
                <Badge premium={p.tags.includes("premium")} />
            </>)),
            "Name-TextOnly": visiblePortfolios.map(p => (`${p.author.replace("TuringTrader", "TT")} ${p.title}`)),

            "Average Return": visiblePortfolios.map(p => `${p["cagr-max"].toFixed(2)}%`),
            "Estimated Return": visiblePortfolios.map(p => `${p["cagr-5th-taxed"].toFixed(2)}%`),

            "Max Drawdown": visiblePortfolios.map(p => `${p["mdd"].toFixed(2)}%`),
            "Estimated Drawdown": visiblePortfolios.map(p => `${p["mdd-5th"].toFixed(2)}%`),

            "Martin Ratio": visiblePortfolios.map(p => `${p["martin"].toFixed(2)}`),
            "Estimated Martin Ratio": visiblePortfolios.map(p => `${p["martin-5th"].toFixed(2)}`),

            // these are required for the scatter chart
            "Ulcer Index": visiblePortfolios.map(p => `${p["ulcer"].toFixed(2)}%`),
            "URL": visiblePortfolios.map(p => `/portfolios/${p.slug}/`),
        }
        setTableData(tmpTable)
    }, [visiblePortfolios, portfolioMetrics, portfolioFilter])

    //---------- render page
    return (
        <Page
            title="Portfolios"
            description="TuringTrader tracks more than three dozen tactical portfolios. Find your perfect match here."
            location={location}
        >

            <h1>Portfolios</h1>

            <p>
                Take your time to find a portfolio that matches your
                financial objectives and comfort level. The most important
                choice criteria to consider are your account size and
                investment horizon, your risk tolerance and the tax status
                of your account.
            </p>
            <p>
                The two most important aspects of any investment are its
                risk and return. This chart visualizes our portfolios in
                these critical dimensions:
            </p>

            <PortfolioScatter data={tableData} filtered={portfolioFilter != null} />

            <div id="filter">
                {portfolioFilter === null || typeof (portfolioFilter) === 'undefined' ?
                    (<>
                        <br />
                        <BoxBorder>
                            <p>
                                Our <strong>portfolio wizard</strong> greatly simplifies finding
                                the right portfolio for you. After just a few clicks, our wizard
                                displays the portfolios most likely matching your investment goals.
                            </p>
                            <ButtonPrimary text="start the portfolio wizard" onClick={() => setWiz(true)} />
                            &nbsp;&nbsp;&nbsp;
                            <ButtonHelp text="how to choose a portfolio" to="/help/find-your-portfolio/" />
                        </BoxBorder>
                        <br />
                        <br />
                    </>) :
                    (<>
                        <br />
                        <BoxBorder>
                            <p>
                                The portfolios shown match your chosen investment criteria:
                            </p>
                            <ul>
                                {portfolioFilter.dollarsToInvest !== null && (
                                    <li>Account size: <strong>{formatCurrency(portfolioFilter.dollarsToInvest, 0)}</strong></li>
                                )}
                                {portfolioFilter.yearsToInvest !== null && (
                                    <li>Investment period: <strong>{portfolioFilter.yearsToInvest} years</strong></li>
                                )}
                                {portfolioFilter.maxDrawdown !== null && (
                                    <li>Risk tolerance: <strong>{portfolioFilter.maxDrawdown}% drawdown during recessions</strong></li>
                                )}
                                {portfolioFilter.taxableAccount !== null && (
                                    <li>Account type: <strong>{portfolioFilter.taxableAccount > 0 ? "taxable" : "tax-deferred"}</strong></li>
                                )}
                                {portfolioFilter.tag !== null && (
                                    <li>Portfolio tag: <strong>{portfolioFilter.tag}</strong></li>
                                )}
                            </ul>
                            <p>
                                The metrics shown are <strong>pessimistic estimates</strong> based on
                                your investment period and tax status. The nominal backtested returns
                                are significantly higher. Read our{" "}
                                <Link href="/articles/portfolio-wizard/">background article</Link>{" "}
                                to learn about our methodology.
                            </p>
                            <ButtonSecondary text="restart the portfolio wizard" onClick={() => setWiz(true)} />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <ButtonSecondary text="reset all filters" to="#filter" />
                        </BoxBorder>
                        <br />
                    </>)}

                <PortfolioTable data={tableData} filtered={portfolioFilter != null} sortable={!numPortfolios || numPortfolios > 5} />
                {filteredPortfolios?.length > visiblePortfolios?.length && (
                    <ButtonSecondary text="show more" onClick={() => setNumPortfolios(999)} />
                )}
            </div>

            {showWiz && <PortfolioWiz onCancel={() => setWiz(false)} />}
        </Page>
    )
}

export default PortfoliosPage

//==============================================================================
// end of file
