//==============================================================================
// Project:     www.TuringTrader.com
// Name:        components/widgets/portfolio-wiz
// Description: Portfolio finder wizard.
// Created:     FUB, May 30, 2022
//==============================================================================

import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { ButtonPrimary, ButtonSecondary } from "./button"
import { Popup } from "./popup"

//------------------------------------------------------------------------------
// debug stuff

const DEBUG_MSG = (msg) => null // eslint-disable-line no-unused-vars
//const DEBUG_MSG = (msg) => console.log(`PORTFOLIO-WIZ: ${msg}`) // eslint-disable-line no-unused-vars
const ERROR_MSG = (msg) => console.error(`PORTFOLIO-WIZ: ${msg}`) // eslint-disable-line no-unused-vars

//------------------------------------------------------------------------------
const ButtonRow = styled.div`
    display: inline-flex;
    gap: 1em;
`

//------------------------------------------------------------------------------
const Step1 = ({ setStep, /*filter,*/ setFilter }) => {
    const filter = ""
    const [capital, setCapital] = useState("")
    const next = () => {
        const filterOut = capital !== "" ?
            `${filter}${filter.length === 0 ? "?" : "&"}c=${capital}` :
            filter
        setFilter(filterOut)
        setStep(2)
    }
    const skip = () => {
        setStep(2)
    }

    return (
        <Popup title="Portfolio Wizard (1/4)" onCancel={setStep}>
            <p>
                How much money are you investing?
            </p>
            <p>
                <input
                    type="number"
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    value={capital}
                    onChange={(event) => setCapital(event.target.value)}
                    key="investment-amount"
                /> US$
            </p>
            <p><small><em>
                Every investment comes with a minimum capital requirement.
                Therefore, the investment amount is a critical parameter
                in chosing a suitable portfolio.
            </em></small></p>


            <ButtonRow>
                <ButtonPrimary
                    text="next"
                    onClick={next}
                />
                <ButtonSecondary
                    text="skip this question"
                    onClick={skip}
                />
            </ButtonRow>
        </Popup>
    )
}

const Step2 = ({ setStep, filter, setFilter }) => {
    const [years, setYears] = useState("")
    const next = () => {
        const filterOut = years !== "" ?
            `${filter}${filter.length === 0 ? "?" : "&"}y=${years}` :
            filter
        setFilter(filterOut)
        setStep(3)
    }
    const skip = () => {
        setStep(3)
    }

    return (
        <Popup title="Portfolio Wizard (2/4)" onCancel={setStep}>
            <p>
                How many years will you hold this investment?
            </p>
            <p>
                <input
                    type="number"
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    value={years}
                    onChange={(event) => setYears(event.target.value)}
                    key="investment-years"
                /> years
            </p>
            <p><small><em>
                Investments are subject to volatility, which affects shorter
                investment time frames more than longer ones. Therefore,
                the investment period is a critical parameter in selecting
                the optimal volatility level.
            </em></small></p>

            <ButtonRow>
                <ButtonPrimary
                    text="next"
                    onClick={next}
                />
                <ButtonSecondary
                    text="skip this question"
                    onClick={skip}
                />
            </ButtonRow>
        </Popup>
    )
}

const Step3 = ({ setStep, filter, setFilter }) => {
    const [riskLevel, setRiskLevel] = useState("")
    const next = () => {
        const filterOut = riskLevel !== "" ?
            `${filter}${filter.length === 0 ? "?" : "&"}r=${riskLevel}` :
            filter
        setFilter(filterOut)
        setStep(4)
    }
    const skip = () => {
        setStep(4)
    }

    return (
        <Popup title="Portfolio Wizard (3/4)" onCancel={setStep}>
            <p>
                What is your risk tolerance?
            </p>
            <p>
                <input
                    type="number"
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    value={riskLevel}
                    onChange={(event) => setRiskLevel(event.target.value)}
                    key="investment-mdd"
                /> % drawdown during a recession
            </p>
            <p><small><em>
                Investments differ in the level of risk they carry. Therefore,
                your personal risk tolerance is an important parameter in finding
                a portfolio you feel comfortable with.
            </em></small></p>

            <ButtonRow>
                <ButtonPrimary
                    text="next"
                    onClick={next}
                />
                <ButtonSecondary
                    text="skip this question"
                    onClick={skip}
                />
            </ButtonRow>
        </Popup>
    )
}

const Step4 = ({ setStep, filter /*, setFilter*/ }) => {
    const [taxable, setTaxable] = useState("")
    const next = () => {
        const filterOut = taxable !== "" ?
            `${filter}${filter.length === 0 ? "?" : "&"}t=${taxable}` :
            filter
        setStep() // reset & close
        navigate(`/portfolios/${filterOut}#filter`)
    }
    const skip = () => {
        setStep() // reset & close
        navigate(`/portfolios/${filter}#filter`)
    }

    return (
        <Popup title="Portfolio Wizard (4/4)" onCancel={setStep}>
            <p>
                What is the tax status of your investment account?
            </p>
            <p>
                <label>
                    <input
                        type="radio"
                        name="taxation"
                        value={taxable === false}
                        //onChange={(e) => setTaxable(e.target.value)}
                        onChange={(e) => setTaxable(0)}
                        key="investment-taxation"
                    />
                    Tax-deferred account (e.g. your IRA, 401(k), or non-U.S. account)
                </label><br />
                <label>
                    <input
                        type="radio"
                        name="taxation"
                        value={taxable === true}
                        //onChange={(e) => setTaxable(e.target.value)}
                        onChange={(e) => setTaxable(1)}
                        key="investment-taxation"
                    />
                    Taxable account (e.g. your joint brokerage account)
                </label>
            </p>
            <p><small><em>
                Interest payments and capital gains of your investments
                are subject to taxation. Therefore, the tax-status of your
                investment account is an important parameter to determine
                the best assets to hold and the appropriate trading approach.
            </em></small></p>

            <ButtonRow>
                <ButtonPrimary
                    text="next"
                    onClick={next}
                />
                <ButtonSecondary
                    text="skip this question"
                    onClick={skip}
                />
            </ButtonRow>
        </Popup>
    )
}



//------------------------------------------------------------------------------
export const PortfolioWiz = ({ onCancel }) => {
    const [step, setStep] = useState(1)
    const [filter, setFilter] = useState("")

    const setResetStep = (n) => {
        if (n) {
            setStep(n)
        } else {
            setStep(1)
            onCancel()
        }
    }

    return (<>
        {step === 1 && (<Step1
            setStep={setResetStep}
            filter={filter} setFilter={setFilter}
        />)}
        {step === 2 && (<Step2
            setStep={setResetStep}
            filter={filter} setFilter={setFilter}
        />)}
        {step === 3 && (<Step3
            setStep={setResetStep}
            filter={filter} setFilter={setFilter}
        />)}
        {step === 4 && (<Step4
            setStep={setResetStep}
            filter={filter} setFilter={setFilter}
        />)}
    </>)
}

//==============================================================================
// end of file
